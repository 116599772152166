import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hero'
import Container from '../components/container'
import BlockContent from '../components/block-content'

export const query = graphql`
  query CookiesPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)3437e71a-89ae-4178-8ca3-89c3d0fbce21/" }) {
      title
      _rawBody
      metaTitle
      metaDescription
      metaKeywords
    }
  }
`

const CookiesPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Cookie policy" page data. Open the studio at http://localhost:3333 and add "Cookie policy" page data and restart the development server.'
    )
  }

  return (
    <Layout palette="invert">
        <SEO title={page.metaTitle || page.title} description={page.metaDescription} keywords={page.metaKeywords} />

        <Hero palette="invert">
            {page.title}
        </Hero>
      
        <Container>
            <BlockContent blocks={page._rawBody} />
        </Container>
    </Layout>
  )
}
CookiesPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default CookiesPage
